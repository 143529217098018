.inputs_container {
  .inputs_box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 29px;
    max-height: 360px;
    overflow-x: hidden;
    padding-top: 20px;
  }
}
