.table_filter_container {
  color: var(--Secondary-text, #707070);
  font-size: var(--ft__20);
  text-transform: capitalize;
  .add_filter_btn {
    color: var(--primary-purple-text, #6278ff);
    text-shadow: 0px 8px 10px rgba(98, 120, 255, 0.16);
    font-size: 25px;
    font-style: normal;
    text-transform: capitalize;
    .add_icon {
      width: 20px;
      height: 20px;
      background-color: var(--Primary-purple, #6278ff);
      box-shadow: 0px 8px 10px rgba(98, 120, 255, 0.16);
      border-radius: 6px;
    }
  }
}
.filter_row_container {
  width: 100%;
  display: flex;
  &.unactive {
    opacity: 0.3;
  }
  .form_box {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__16);
    text-transform: capitalize;
    .input {
      all: unset;
      display: block;
      border-radius: 15px;
      background: var(--gray-background, #f7f7f7);
      padding: 8px;
      min-width: 120px;
      font-size: 20px;
      width: 100%;
      min-width: auto;
      &::placeholder {
        color: var(--Secondary-text, #707070);
        font-size: 20px;
      }
    }
  }
  .filter_type {
    text-transform: capitalize;
    background: var(--gray-background, #f7f7f7);
    color: var(--Primary-purple, #6278ff);
    border-radius: 15px;
    height: 44px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 500;
    button {
      font-size: 24px;
      display: flex;
      align-items: center;
    }
  }
}

// ====== start media max-width: 1399px ======= //
@media (max-width: 1399px) {
  .table_filter_container {
    // font-size: var(--ft__18);
    font-size: 14px;
    .add_filter_btn {
      font-size: 20px;
      .add_icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
// ====== end media max-width: 1399px ======= //
// ====== start media max-width: 1024px ======= //
@media (max-width: 767px) {
  .filter_row_container {
    flex-wrap: wrap;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 4px;
    > div {
      &:first-of-type {
        button {
          background-color: #fff;
          & + div {
            background-color: #fff;
          }
        }
      }
    }
    .form_box {
      flex-wrap: wrap;
      > div {
        flex: 1;
      }
      .input {
        background-color: #fff;
        flex: 1;
        width: 100%;
        min-width: auto;
        border-radius: 10px;
        padding: 10px 12px;
        font-size: 14px;
        min-width: 100px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .filter_type {
      background-color: #ffff;
    }
  }
}
// ====== end media max-width: 1024px ======= //
