.dropdown {
  width: 470px;
  border-radius: 32px;
  box-shadow: 0px 4px 10px 0px rgba(128, 128, 128, 0.16);
  left: 0;
  top: 154%;
  background: white;
  transform: translateX(-25%);
  overflow: hidden;
  .dropdown_content {
    padding: 32px 0 45px;
    .dropdown_header {
      padding: 0 24px;
    }
    .notify_title {
      color: #000;
      font-size: var(--ft__20);
      font-style: normal;
      text-transform: capitalize;
    }
    .mark_all_read {
      color: var(--Secondary-Green, #0acf83);
      font-size: var(--ft__16);
      text-decoration-line: underline;
      text-transform: capitalize;
    }
    .notifications_items {
      max-height: 440px;
      overflow-x: hidden;
      padding: 0 24px;
    }
  }
}

@media (max-width: 1399px) {
  .dropdown {
    width: 350px;
    border-radius: 24px;
    top: 154%;
    transform: translateX(-25%);
    .dropdown_content {
      padding: 24px 0 32px;
      .dropdown_header {
        padding: 0 18px;
      }
      .notify_title {
        font-size: var(--ft__16);
      }
      .mark_all_read {
        font-size: 12px;
      }
      .notifications_items {
        max-height: 440px;
        padding: 0 24px;
      }
    }
  }
}
