.text {
  color: var(--Secondary-text, #707070);
  font-size: var(--ft__20);
  line-height: 147%; /* 29.4px */
  text-transform: capitalize;
  margin-bottom: 52px;
}
.settings__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  .settings__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      text-transform: capitalize;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
