.filed_container {
  height: 155px;
  width: 100%;
  border-radius: 16px;
  border: 1.5px dashed var(--Border-color, #ddd);
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  .label {
    position: absolute;
    top: 0;
    left: 21px;
    color: var(--Secondary-text, #707070);
    background-color: #fff;
    font-size: var(--ft__16);
    line-height: 147%;
    letter-spacing: 0.18px;
    text-transform: capitalize;
    padding: 10px;
    transform: translate(-0%, -50%);
    z-index: 1;
    font-weight: 400;
    &:lang(ar) {
      right: 21px;
      left: auto;
    }
  }
  .filed_text {
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__22);
    letter-spacing: 0.22px;
    text-transform: capitalize;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    span {
      font-weight: 400;
      &:last-of-type {
        color: var(--Primary-purple, #6278ff);
        font-size: var(--ft__18);
        svg {
          path {
            stroke: var(--Primary-purple, #6278ff);
          }
        }
      }
    }
  }
  .image {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 50%;
    // height: 100%;
    // object-fit: cover;
    // border-radius: 16px;
  }
}
.file_details {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 17px;
  img {
    display: block;
    flex: 1 1;
    object-fit: cover;
    max-width: 400px;
    display: block;
    margin: 0 auto;
    width: 100px;
    border-radius: 4px;
  }
  .file_name {
    flex: 2;
    position: relative;
    background-color: #fff;
    height: 88px;
    box-sizing: border-box;
    padding: 0 24px;
    border-radius: var(--radius-16);
    border: 1.5px solid var(--Border-color, #ddd);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: var(--Secondary-text, #707070);
    font-size: var(ft__22);
    letter-spacing: 0.22px;
    text-transform: capitalize;
    span {
      position: absolute;
      top: 0;
      left: 21px;
      color: var(--Secondary-text, #707070);
      // background: var(--gray-background, #f7f7f7);
      background-color: #fff;
      font-size: var(--ft__16);
      line-height: 147%;
      letter-spacing: 0.18px;
      text-transform: capitalize;
      padding: 0 4px;
      transform: translate(-0%, -50%);
      z-index: 1;
    }
  }
}

// ============== start media max width 1399px =========== //
@media (max-width: 1399px) {
  .filed_container {
    height: 120px;
    .filed_text {
      font-size: var(--ft__18);
      letter-spacing: 0.22px;
      text-transform: capitalize;
      border-radius: 12px;
    }
  }
}
// ============== end media max width 1399px =========== //
