.notify_cards_container {
  display: grid;
  column-gap: 23px;
  row-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.page {
  .members_no {
    color: var(--Primary-purple, #6278ff);
    font-size: var(--ft__20);
    text-transform: capitalize;
  }
}
.PaginationControl {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--gray-background, #f7f7f7);
  margin-top: 2rem;
}
