.inputs_container {
  .inputs_box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 29px;
    max-height: 360px;
    overflow-x: hidden;
    padding-top: 20px;
    > div {
      flex-basis: calc(50% - 7.5px);
      &:last-of-type,
      &:first-of-type {
        flex-basis: 100%;
      }
    }
  }
}
