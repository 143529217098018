.dropdown {
  //   width: 470px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px rgba(128, 128, 128, 0.16);
  left: 0;
  top: 154%;
  background: white;
  transform: translateX(-25%);
  overflow: hidden;
  button {
    padding: 10px 32px 10px 10px;
    transition: 0.3s linear;
    &:lang(ar) {
      padding: 10px 10px 10px 32px;
    }
    &:hover {
      background-color: #dddddd7a;
    }
    &.active {
      background-color: #6278ff;
      color: #fff;
    }
  }
}

@media (max-width: 1399px) {
  .dropdown {
    top: 154%;
    transform: translateX(-25%);
    button {
      padding: 6px 28px 10px 10px;
      font-size: 12px;
      &:lang(ar) {
        padding: 6px 10px 10px 28px;
      }
      &:hover {
        background-color: #dddddd7a;
      }
      &.active {
        background-color: #6278ff;
        color: #fff;
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
