@import "../../../../../styles/variables.scss";

.container {
  height: 145px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-color: $unnamed__color__080E23;
  max-width: 360px;
  margin: 1.5rem 0;
  margin-bottom: 2.5rem;

  .overlay {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .user__balance__wrapper {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    height: 145px;
    padding: 28px 24px 18px 24px;
    color: $unnamed__color__FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // background: linear-gradient(#000, #000000aa), url("assets/icons/circle.svg");

    .balance__wrapper {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .balance {
        color: $unnamed__color__1abcfe;
        font-weight: 600;
      }
    }
    .last__update__date {
      font-size: 12px;
      color: $unnamed__color__dddddd;
      opacity: 0.7;
    }
  }
}
