.user__info__wrapper {
  padding: 1.5rem 1.25rem;
  padding-right: 2rem;
  border-bottom: 4px solid var(--gray-background);

  .section__head {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .user__info {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .settings__btn__wrapper {
      .settings__btn {
        background-color: var(--Primary-purple);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        min-width: 45px;
        height: 45px;
        border-radius: 18px;
        font-size: 1rem;
        box-shadow: 0px 5px 10px #00000022;

        span {
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 2px;
        }
        &.room__info__btn {
          text-shadow: 0px 0px 5px #00000045;
          font-size: 1.5rem !important;
          color: var(--Primary-purple);
          box-shadow: none;
          background-color: #fff;
          span {
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 2px;
          }
        }
      }
    }
    .user__data {
      display: flex;
      column-gap: 1rem;
      .user__avatar__wrapper {
        height: 55px;
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0px 5px 10px #00000036;
        img {
          height: 100%;
          width: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
      .user__details {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .user__name {
          font-size: 1.1rem;
          font-weight: 500;
        }
        .user__join__date {
          font-size: 0.65rem;
          opacity: 0.5;
        }
      }
    }
  }
  .user__facts {
    // margin-left: 4.5rem;
    margin-top: 1.5rem;
    .user__fact__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.75rem 0;
      row-gap: 0.6rem;
      .fact__name {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
        text-transform: capitalize;
      }
      .fact__value {
        // font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        &.active {
          color: var(--Secondary-Green);
          font-weight: 600;
          text-transform: capitalize;
        }
        &.inactive {
          color: var(--Secondary-Orange);
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media screen and (min-width: "576px") {
  .user__facts {
    margin-left: 4.5rem;
  }
  .settings__btn {
    min-width: 155px !important;
    height: 55px !important;
    font-size: 1.25rem !important;
  }
}
