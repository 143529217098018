.main_link {
  gap: 16px;
  width: 100%;
  color: var(--Main-Text, #080e23);
  font-size: var(--ft__18);
  line-height: 147%; /* 26.46px */
  letter-spacing: 0.18px;
  text-transform: capitalize;
  padding: 14px;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
  &.nested {
    font-size: var(--ft__16);
    color: var(--Secondary-text, #707070);
    gap: 8px;
    .icon {
      width: 18px;
      height: 18px;
      svg {
        path {
          stroke: var(--icons-color);
        }
      }
    }
  }
  &.closed_menu {
    padding: 8px;
    justify-content: center;
    border-radius: 8px;
    &.nested {
      svg {
        width: 18px;
        height: 18px;
        path {
          stroke: var(--icons-color);
        }
      }
      &.active,
      &:hover {
        svg {
          path {
            stroke: var(--Primary-purple, #6278ff);
          }
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--Primary-Black, #080e23);
      }
    }
  }
  &:hover {
    color: var(--Primary-purple, #6278ff);
    .icon {
      > svg {
        path {
          stroke: var(--Primary-purple, #6278ff);
        }
      }
    }
  }
  &.active {
    &:not(.nested) {
      background: var(--primary-colors-purple-color-purple-1, #dfe5ff);
    }
    color: var(--Primary-purple, #6278ff);
    .icon {
      > svg {
        path {
          stroke: var(--Primary-purple, #6278ff);
        }
      }
    }
  }
  .icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    > svg {
      width: 100%;
      height: 100%;
      path {
        transition: 0.3s ease-in-out;
        stroke: var(--Primary-Black, #080e23);
        // stroke: var(--Main-Text, #080e23);
      }
    }
  }
  .active_icon {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 30px;
    // top: 252px;
    width: 38px;
    height: 38px;
    transform: translateY(50%);
    transition: 0.3s linear;
  }
}

// =========== Start media max-width: 1399px ======== //
@media (max-width: 1399px) {
  .main_link {
    font-size: var(--ft__16);
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
    &.nested {
      font-size: 12px;
    }
    .icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }
}
// =========== End media max-width: 1399px ======== //
