.page {
  .switch_btn_container {
    display: grid;
    grid-template-rows: repeat(8, auto);
    grid-auto-columns: calc(50% - 40px);
    grid-auto-flow: column dense;
    grid-column-gap: 80px;
    column-gap: 80px;
    margin: 20px 49px 0;
  }
}
