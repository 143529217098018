.inputs_container {
  max-height: 520px;
  overflow-x: hidden;
  padding: 20px 0;
}
.form_footer {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 43px;
  bottom: 0;
  background: var(--gray-background, #f7f7f7);

  > button {
    width: fit-content;
    padding: 16px 24px;
    padding-left: 24px;
    padding-right: 24px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    font-size: 18px;
  }
}
