.card {
  .card_img {
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card_title {
    margin: 16px 0 12px;
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__18);
  }
  .card_desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* limit to 2 lines */
    overflow: hidden;
    color: var(--Secondary-text, #707070);
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 18px;
  }
  .card_date {
    gap: 6px;
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__16);
    text-transform: capitalize;
  }
  .card_options {
    gap: 16px;
    svg {
      width: 100%;
    }
  }
}
