.loader__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;
  .loader {
    border: 8px solid #cecece; /* Light grey */
    border-top: 8px solid #8650dc; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
