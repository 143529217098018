.pagination {
  gap: 16px;
  li {
    &:last-of-type {
      transform: rotate(-90deg);
      &:lang(ar) {
        transform: rotate(90deg);
      }
    }
    &:first-of-type {
      transform: rotate(90deg);
      &:lang(ar) {
        transform: rotate(-90deg);
      }
    }
    &:last-of-type,
    &:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background: var(--Primary-purple, #6278ff);
      svg {
        path {
          stroke: #fff;
        }
      }
      &.disabled {
        background-color: #fff;
      }
    }
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__18);
    text-transform: capitalize;
  }
  .active_link {
    color: var(--Primary-purple, #6278ff);
    text-shadow: 0px 8px 10px rgba(98, 120, 255, 0.16);
  }
}
