.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
  }
  .modal {
    max-width: 90%;
    width: 720px;
    overflow: hidden;
    padding: 32px 0 45px;
    border-radius: 32px;
    border: none;
    outline: none;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .modal_header {
      color: #000;
      font-size: var(--ft__28);
      text-transform: capitalize;
      // margin-bottom: 49px;
      margin-bottom: 35px;
      gap: 24px;
      padding: 0 43px;
      .modal_title {
        color: #000;
        font-size: var(--ft__28);
        text-transform: capitalize;
        font-weight: 400;
      }
      .back_btn {
        transform: rotate(90deg);
        width: 48px;
        height: 48px;
        border-radius: 8px;
        background-color: #dfe5ff;
        &:lang(ar) {
          transform: rotate(-90deg);
        }
        svg {
          path {
            stroke: #a259ff;
          }
        }
      }
    }
  }
  .popup_content {
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    padding: 10px 43px 0;
  }
}

// ========== Start media max-width: 1399px =========== //
@media (max-width: 1399px) {
  .modal_container {
    .modal {
      padding: 40px 0;
      border-radius: 20px;
      .modal_header {
        margin-bottom: 16px;
        gap: 18;
        padding: 0 24px;
        .modal_title {
          font-size: var(--ft__24);
        }
        .back_btn {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          svg {
            width: 18px;
          }
        }
      }
    }
    .popup_content {
      max-height: calc(100vh - 170px);
      padding: 10px 24px 0;
    }
  }
}
// ========== end media max-width: 1399px =========== //
