.card {
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 2rem;
  border-radius: 12px;
  .card_image {
    height: 220px;
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    padding: 24px 37px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    .image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .user_info {
    margin: 8px 0 20px;
    padding-top: 2rem;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: left;
    }
    .green {
      color: var(--Secondary-Green, #0acf83);
      font-size: var(--ft__20);
      text-transform: capitalize;
    }
  }
  .card_title {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__18);
    font-weight: 400;
    letter-spacing: 0.18px;
    text-transform: capitalize;
  }
  .desc {
    color: var(--Secondary-text, #707070);
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 147%; /* 20.58px */
    margin-bottom: 18px;
  }
  .card_footer {
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__16);
    line-height: 147%; /* 23.52px */
    text-transform: capitalize;
    padding-bottom: 1rem;
  }
}

.facts {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .fact {
    .icon {
      font-size: 1.25rem;
      margin: 0 5px;
      color: var(--Secondary-Orange);
    }
    .comment {
      font-size: 1.25rem;
      margin: 0 5px;
      color: var(--Secondary_Blue);
    }
    .value {
      opacity: 0.7;
    }
  }
}
