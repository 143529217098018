.toggle__btn {
  all: unset;
  cursor: pointer;
  width: 52px;
  height: 32px;
  background-color: #f7f7f7;
  border-radius: 400px;
  position: relative;
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    // content: url("../../../../assets/ar_flag.png");
    overflow: hidden;
    position: absolute;
    left: 4px;
    top: 16px;
    background-color: #dddddd;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  &:checked {
    background-color: #ccfcd6;
    &::after {
      background-color: #0acf83;
      left: auto;
      right: 2px;
      width: 28px;
      height: 28px;
    }
  }
}
