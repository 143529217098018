.container {
  margin: 20px 49px 0;
  margin-bottom: 48px;
  .content_container {
    max-width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 110px;
    margin-bottom: 48px;
    row-gap: 60px;
    .main_text {
      color: var(--Secondary-text, #707070);
      font-size: var(--ft__20);
      text-transform: capitalize;
      margin-bottom: 18px;
    }
    .sub_text {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__16);
      text-transform: capitalize;
    }
  }
}
