.login_page {
  padding: 82px 0;
  min-height: 100vh;
  .row {
    gap: 100px;
    min-height: calc(100vh - 164px);
  }
  .left_content {
    padding: 40px 0;
  }
  .login_image {
    background-color: var(--Primary-purple);
    border-radius: var(--radius);
    overflow: hidden;
    .login_bg {
      object-fit: cover;
    }
  }
  .login_title {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__32);
    font-weight: var(--ft__w__regular);
    line-height: 147%; /* 47.04px */
    letter-spacing: 0.32px;
    text-transform: capitalize;
    margin-bottom: 24px;
  }
  .login_subtitle {
    color: var(--Secondary-text, #707070);
    text-align: center;
    font-size: var(--ft__22);
    font-weight: var(--ft__w__regular);
    line-height: 147%; /* 32.34px */
    letter-spacing: 0.22px;
    text-transform: capitalize;
    margin-bottom: 80px;
  }
  .inputs__container {
    margin-bottom: 88px;
  }
}

@media (max-width: 1399px) {
  .login_page {
    padding: 40px 0;
    .row {
      gap: 40px;
      min-height: calc(100vh - 80px);
    }
    .login_title {
      font-size: var(--ft__28);
      margin-bottom: 14px;
    }
    .login_subtitle {
      font-size: var(--ft__18);
      margin-bottom: 42px;
    }
    .inputs__container {
      gap: 28px !important;
    }
    .inputs__container {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 991px) {
  .login_page {
    height: auto !important;
    min-height: 100vh;
    .row {
      flex-wrap: nowrap;
      justify-content: center;
    }
    .login_content {
      // flex: 1;
      .login_image {
        min-height: 400px;
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}
