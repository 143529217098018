.main_card {
  padding: 16px 31px;
  border-radius: var(--radius-16);
  background: hsl(229deg 100% 94% / 50%);
  white-space: pre;
  // gap: 16px;
  &.cyan {
    background: hsl(180deg 96% 91% / 50%);
    .card_header {
      .card_icon {
        background-color: var(--Secondary-Cyan, #1abcfe);
      }
    }
    .card_num {
      color: var(--Secondary-Cyan, #1abcfe);
    }
  }
  &.yellow {
    background: hsl(45deg 95% 92% / 50%);
    .card_header {
      .card_icon {
        background-color: var(--Primary-Yellow, #fcb042);
      }
    }
    .card_num {
      color: var(--Primary-Yellow, #fcb042);
    }
  }
  &.purple {
    background: hsl(277deg 100% 93% / 50%);
    .card_header {
      .card_icon {
        background-color: var(--Secondary-Purple, #a259ff);
      }
    }
    .card_num {
      color: var(--Secondary-Purple, #a259ff);
    }
  }
  .card_header {
    .card_icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: var(--Primary-purple, #6278ff);
      margin-right: 4px;
      &:lang(ar) {
        margin-right: 0px;
        margin-left: 10px;
      }
      > svg {
        width: 24px;
        height: 24px;

        path {
          stroke: #fff;
        }
      }
    }
  }
  .card_footer {
    color: var(--Secondary-text, #707070);
    font-size: 14px;
    line-height: 147%; /* 20.58px */
    text-transform: capitalize;
    .card_title {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__18);
      line-height: 147%; /* 26.46px */
      text-transform: capitalize;
      margin: 6px 0 8px;
      font-weight: 400;
      // width: 100%;
      // padding-left: 16px;
      // &:lang(ar) {
      //   padding-left: 0;
      //   padding-right: 16px;
      // }
    }
    .card_num {
      color: var(--Primary-purple, #6278ff);
      font-size: var(--ft__28);
      line-height: 147%; /* 41.16px */
      letter-spacing: 0.28px;
      text-transform: capitalize;
      margin-bottom: 8px;
    }
    .card_status {
      &.increase {
        .status_icon {
          transform: rotate(0);
          background-color: var(--Secondary-Orange, #f24e1e);
        }
        > svg {
          flex-shrink: 0;
        }
        .card_precentage {
          color: var(--Secondary-Orange, #f24e1e);
        }
      }
      .text_foot {
        color: var(--Secondary-text, #707070);
        font-weight: 400;
      }
      .status_icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--Secondary-Green, #0acf83);
        color: #fff;
        font-size: 28px;
        transform: rotate(180deg);
        > svg {
          flex-shrink: 0;
        }
      }
      .card_precentage {
        color: var(--Secondary-Green, #0acf83);
        font-size: var(--ft__18);
        line-height: 147%; /* 26.46px */
        text-transform: capitalize;
        margin: 0 10px 0 5px;
        font-weight: 400;
        &:lang(ar) {
          margin: 0 5px 0 10px;
        }
      }
    }
  }
}

// =========== Start media max-width: 1399px ======== //
@media (max-width: 1399px) {
  .main_card {
    padding: 16px 31px;
    .card_header {
      .card_icon {
        width: 32px;
        height: 32px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .card_footer {
      .card_title {
        margin-top: 3px;
        font-size: 16px;
        // padding-left: 10px;
      }
      .card_num {
        font-size: 24px;
      }
      .card_status {
        .text_foot {
          font-size: 12px;
        }
        .status_icon {
        }
        .card_precentage {
          font-size: 16px;
        }
      }
    }
  }
}
// =========== End media max-width: 1399px ======== //
