.search_box {
  padding: 20px 24px;
  border-radius: 16px;
  border: 1px solid var(--Border-color, #ddd);
  width: 512px;
  max-width: 95%;
  gap: 13px;
  position: relative;
  overflow: hidden;
  // margin: 0 0 0 auto;
  .search_input {
    all: unset;
    position: absolute;
    top: 0;
    left: 61px;
    width: calc(100% - 85px);
    color: var(--Secondary-text, #707070);
    height: 100%;
    &:lang(ar) {
      left: auto;
      right: 61px;
    }
    &::placeholder {
      color: var(--Secondary-text, #707070);
      font-size: var(--ft__16);
      text-transform: capitalize;
      font-weight: 400;
    }
  }
}

// ====== start media max width 1399 ===== //
@media (max-width: 1399px) {
  .search_box {
    padding: 10px 16px;
    border-radius: 10px;
    .search_input {
      left: 52px;
      font-size: 14px;
      &:lang(ar) {
        left: auto;
        right: 52px;
      }
      &::placeholder {
        // font-size: var(--ft__14);
        font-size: 12px;
      }
    }
    svg {
      width: 18px;
    }
  }
}
// ====== end media max width 1399 ===== //
// ====== start media max width 1024px ===== //
@media (max-width: 1024px) {
  .search_box {
    width: 300px;
    max-width: 75%;
  }
}
// ====== end media max width 1024px ===== //
