.users_filter_container {
  margin-top: 34px;
  height: calc(100vh - 142px);
  position: sticky;
  top: 110px;
  .user__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // padding: 0 12px;
    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 50px;
      background-color: var(--Border-color, #ddd);
    }
    li {
      flex: 1;
      display: flex;
      align-items: center;

      &:first-of-type {
        justify-content: flex-start;
      }
      &:nth-of-type(2) {
        justify-content: center;
      }
      &:nth-child(3) {
        justify-content: end;
      }
      .filter_btn {
        color: var(--Secondary-text, #707070);
        font-size: var(--ft__20);
        text-transform: capitalize;
        transition: 0.3s linear;
        width: fit-content;
        position: relative;
        padding: 0 12px;
        display: block;
        &::after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: var(--Primary-purple, #6278ff);
          transition: 0.3s linear;
          border-radius: 50px;
          z-index: 1;
          //   display: none;
          //   width: 0;
          transform: scaleX(0);
        }
        &.active {
          color: var(--Primary-purple, #6278ff);
          &::after {
            // display: block;
            // width: 100%;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .room_user_list {
    margin-top: 50px;
    gap: 32px;
    overflow-x: hidden;
    height: 400px;
    height: calc(100% - 83px);

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      // background: var(--gray-background, #f7f7f7);
      background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #6277ff88;
      border-radius: 50px;
    }
    .row_details {
      gap: 32px;
      .user_name {
        color: var(--Main-Text, #080e23);
        font-size: var(--ft__16);
        text-transform: capitalize;
      }
    }
  }
}
