// FONTS
$main__font__family: "poppins";

// COLORS
$unnamed__color__FFFFFF: #ffffff;
$unnamed__color__080E23: #080e23;
$unnamed__color__1abcfe: #1abcfe;
$unnamed__color__dddddd: #dddddd;
$unnamed__color__f7f7f7: #f7f7f7;
$unnamed__color__1abcfe: #1abcfe;
$unnamed__color__17004f: #17004f;
$unnamed__color__8650dc: #8650dc;
$unnamed__color__202020: #202020;
$unnamed__color__0acf83: #0acf83;
$unnamed__color__9a9a9a: #9a9a9a;
$unnamed__color__f24e1e: #f24e1e;
$unnamed__color__d9dfff: #d9dfff;
$unnamed__color__8650dc: #8650dc;
$unnamed__color__09cf83: #09cf83;
$unnamed__color__707070: #707070;
$unnamed__color__2d2d2d: #2d2d2d;
$unnamed__color__fcb042: #fcb042;
