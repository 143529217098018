.active_btn {
  padding: 7px 14px;
  border-radius: 6px;
  background-color: #6278ff36;
  color: var(--Primary-purple);
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.1s;
  &.active {
    color: #fff;
    background-color: var(--Primary-purple);
  }
}
