.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .copy {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(calc(-100% - 10px));
    font-size: 13px;
    background-color: var(--primary_Purple_Text);
    padding: 4px 10px;
    color: #fff;
    border-radius: 4px;
    // background-color: #fff;
    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 6px;
      border: 8px solid;
      border-color: transparent;
      border-top-color: var(--primary_Purple_Text);
    }
  }
}

@media (max-width: 1399px) {
  .button {
    gap: 4px;
    font-size: 12px;
    > svg {
      width: 20px;
    }
    .copy {
      font-size: 10px;
    }
  }
}
