.page {
  .page_content {
    display: flex;
    gap: var(--width_border);
    overflow: hidden;
    > div {
      flex: 2;
    }
    .left_side {
      flex: 3;
      > div {
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }
}
