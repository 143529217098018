.error_box {
  padding: 24px;
  background-color: #ff000033;
  color: rgb(87, 1, 1);
  margin: 40px;
  top: 41px;
  position: relative;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
}
