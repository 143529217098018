@import "../../../../styles/variables.scss";

.member__wallet__wrapper {
  padding: 1.5rem 1.25rem;
  border-bottom: 4px solid var(--gray-background);
  .section__head {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .transactions__sets__wrapper {
    padding-right: 1.5rem;
    height: 480px !important;
    overflow: scroll;
    margin-top: 1.5rem;
    .transactions__set {
      padding: 0.75rem 0;
      .transactions__wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
      .set_date {
        color: $unnamed__color__9a9a9a;
        font-size: 12px;
      }
    }
  }
  .member__transactions__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .transactions__filter__btns {
      // width: 70% !important;
      display: flex;
      align-items: center;
      column-gap: 12px;
      overflow-x: scroll !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: "576px") {
  .member__wallet__wrapper {
    .member__transactions__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
      .transactions__filter__btns {
        width: 100% !important;
      }
    }
  }
}

@media screen and (min-width: "767px") {
  .member__wallet__wrapper {
    border-bottom: none !important;
  }
}
