.post_container {
  .post_image {
    height: 216px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .green {
    color: var(--Secondary-Green, #0acf83);
    font-size: var(--ft__20);
    text-transform: capitalize;
  }
  .post_title {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__18);
    font-weight: 500;
    line-height: 147%; /* 26.46px */
    letter-spacing: 0.18px;
    text-transform: capitalize;
    margin-bottom: 16px;
  }
  .post_text {
    color: var(--Secondary-text, #707070);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 147%; /* 20.58px */
    letter-spacing: 0.14px;
    // text-transform: capitalize;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 15; /* limit to 2 lines */
    margin-bottom: 30px;
  }
  .delete_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-radius: 20px;
    border: 2px solid var(--Secondary-Orange, #f24e1e);
    color: var(--Secondary-Orange, #f24e1e);
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    line-height: 147%; /* 36.75px */
    letter-spacing: 0.25px;
    text-transform: capitalize;
    box-sizing: border-box;
  }
}
.facts {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .fact {
    .icon {
      font-size: 1.25rem;
      margin: 0 5px;
      color: var(--Secondary-Orange);
    }
    .comment {
      font-size: 1.25rem;
      margin: 0 5px;
      color: var(--Secondary_Blue);
    }
    .value {
      opacity: 0.7;
    }
  }
}
