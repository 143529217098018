.page {
  .permission_details {
    margin-bottom: 46px;
    .permession_icon {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      background-color: var(--Secondary-Purple, #a259ff);
    }
    .role {
      color: #000;
      font-size: 25px;
      text-transform: capitalize;
      margin-bottom: 13px;
    }
    .num_permissions {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__18);
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  .switch_btn_container {
    display: grid;
    grid-template-rows: repeat(8, auto);
    grid-auto-columns: calc(50% - 40px);
    grid-auto-flow: column dense;
    grid-column-gap: 80px;
    column-gap: 80px;
    margin: 20px 49px 0;
  }
}
