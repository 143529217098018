.message_box {
  padding: 13px 16px;
  border-radius: 12px;
  background: var(--gray-background, #f7f7f7);
  width: 510px;
  max-width: 100%;
  .message_head {
    margin-bottom: 4px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .user_name {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__16);
      text-transform: capitalize;
    }
    .message_date {
      color: var(--Secondary-text, #707070);
      font-size: 11px;
      text-transform: capitalize;
    }
  }
  .message {
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__16);
    text-transform: capitalize;
    padding-left: 73px;
  }
}
