.main_card {
  padding: 24px;
  background-color: #fff;
  height: 100%;
}

// ===== start media max width  1399 ===== //
@media (max-width: 1399px) {
  .main_card {
    padding: 18px;
  }
}
// ===== end media max width  1399 ===== //
