.nav {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px 58px;
  background-color: #fff;
  border-bottom: var(--width_border) solid var(--gray-background, #f7f7f7);
  z-index: 1000;
  .logo {
    height: 100%;
    width: 234px;
    border-right: var(--width_border) solid var(--gray-background, #f7f7f7);
    &:lang(ar) {
      border-right: none;
      border-left: var(--width_border) solid var(--gray-background, #f7f7f7);
    }
  }
  .header_details {
    gap: 32px;
    .notify_btn {
      &::after {
        display: none;
      }
      .notify_icon {
        top: 5px;
        right: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--Secondary-Orange, #f24e1e);
        border: 1px solid #fff;
      }
    }
    .lang_btn {
      gap: 8px;
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__16);
      text-transform: capitalize;
      .lang_image {
        pointer-events: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user_btn {
      .user_image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
      }
      .user_name {
        color: var(--Main-Text, #080e23);

        /* Heading Xs/Medium */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 147%; /* 26.46px */
        letter-spacing: 0.18px;
        text-transform: capitalize;
        margin-bottom: 4px;
      }
      .user_role {
        color: var(--Secondary-text, #707070);
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
      }
    }
  }
  .mobile_menu_btn {
    display: none;
  }
}

// =============== start media max width 1399px ============== //
@media (max-width: 1399px) {
  .nav {
    height: 58px;
    padding: 0 24px;
    .logo {
      width: 196px;
      img {
        width: 72px;
      }
    }
    .header_details {
      gap: 20px;
      .notify_btn {
        width: 20px;
        height: 20px;
        svg {
          width: 100%;
          height: 100%;
        }
        .notify_icon {
          width: 6px;
          height: 6px;
          right: 3px;
          top: 6px;
        }
      }
      .lang_btn {
        gap: 4px;
        font-size: 14px;
        .lang_image {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
        }
      }
      .user_btn {
        .user_image {
          width: 28px;
          height: 28px;
        }
        .user_name {
          font-size: 14px;
          margin-bottom: 0px;
        }
        .user_role {
          font-size: 11px;
        }
      }
    }
  }
}
// =============== end media max width 1399px ============== //
// =============== start media max width 991px ============== //
@media (max-width: 991px) {
  .nav {
    .mobile_menu_btn {
      // padding: 6px 8px;
      background-color: #6278ff;
      color: #fff;
      border-radius: 4px;
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s linear;
      width: 28px;
      height: 28px;
      font-size: 20px;
      &.closed {
        border-radius: 50%;
      }
      &:lang(ar) {
        right: auto;
        left: 10px;
      }
    }
  }
}
// =============== end media max width 575px ============== //
@media (max-width: 575px) {
  .nav {
    padding: 0px 18px;
    border: var(--width_border) solid var(--gray-background, #f7f7f7);
    .logo {
      height: 100%;
      width: 198px;
    }
    .user_btn {
      .user_role,
      .user_name {
        display: none;
      }
    }
  }
}
// =============== end media max width 575px ============== //
// =============== end media max width 450px ============== //
@media (max-width: 450px) {
  .nav {
    .logo {
      border: none;
      &:lang(ar) {
        border: none;
      }
    }
    .mobile_menu_btn {
      left: 100px;
      right: auto;
      width: 20px;
      height: 20px;
      font-size: 14px;
      right: 48px;
      &:lang(ar) {
        right: auto;
        left: 48px;
      }
    }
  }
}
// =============== end media max width 450px ============== //
