.inputs_container {
  .inputs_box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 29px;
    max-height: 360px;
    overflow-x: hidden;
    padding-top: 20px;
    > div {
      flex-basis: 100%;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        flex-basis: calc(50% - 7.5px);
      }
    }
  }
}
.switch {
  color: var(--Main-Text, #080e23);
  font-size: var(--ft__20);
  text-transform: capitalize;
}
