.submit__btn {
  all: unset;
  cursor: pointer;
  outline: none;
  border-radius: var(--radius);
  height: 80px;
  width: 100%;
  background: var(--Primary-purple, #6278ff);
  color: #fff;
  text-align: center;
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  letter-spacing: 0.25px;
  font-size: 25px;

  &:disabled {
    cursor: not-allowed;
    background-color: #979797;
    border: #979797;
  }
  &.submitting {
    cursor: not-allowed;
  }
  &.outline {
    background-color: #fff;
    border: 2px solid var(--Border-color, #ddd);
    color: var(--Secondary-text, #707070);
    box-sizing: border-box;
  }
  .spinner {
    color: var(--Primary-Purple);
    margin-top: 6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 auto;
    border-left-color: transparent;
    transition: 0.3s linear;
    animation: spinning 1s linear infinite;
    &.deleteSpinner {
      border-color: #333;
      border-left-color: transparent;
    }
  }
}
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1399px) {
  .submit__btn {
    height: 52px;
    border-radius: 12px;
    font-size: 18px;
  }
}
