.member__interests__wrapper {
  padding: 1.5rem 1.25rem;
  // padding-right: 2.5rem;
  border-bottom: 4px solid var(--gray-background);
  .section__head {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.4rem;
    margin-top: 1.25rem;

    .interest__card {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // font-size: 13px;
      text-transform: capitalize;
      font-weight: 500;
      padding: 0 1rem;
      background-color: var(--gray-background);
      border-radius: 50px;
      margin: 0.6rem 0.4rem;
      height: 32px;
    }
  }
}
