.notify_item {
  padding: 16px 0 14px;
  &:not(:last-of-type) {
    border-bottom: 2px solid var(--Border-color, #ddd);
  }
  .notify_header {
    margin-bottom: 13px;

    .user_info {
      gap: 15px;
      img {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        &:nth-child(2) {
          width: 30px !important;
          height: 30px !important;
          bottom: 5px !important;
        }
      }
      .user_name {
        color: var(--Main-Text, #080e23);
        font-size: var(--ft__18);
        text-transform: capitalize;
        line-height: 147%; /* 26.46px */
        letter-spacing: 0.18px;
      }
    }
    .notify_date {
      color: var(--Secondary-text, #707070);
      font-size: 11px;
      text-transform: capitalize;
      text-wrap: nowrap;
    }
  }
  .notify_text {
    color: var(--Main-Text, #080e23);
    font-size: 14px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* limit to 2 lines */
    line-height: 147%; /* 20.58px */
    letter-spacing: 0.14px;
    font-weight: 400;
    padding-left: 60px;
  }
}

@media (max-width: 1399px) {
  .notify_item {
    padding: 10px 0 12px;
    .notify_header {
      margin-bottom: 6px;
      .user_info {
        gap: 10px;
        img {
          width: 26px !important;
          height: 26px !important;
          border-radius: 50%;
          &:nth-child(2) {
            width: 20px !important;
            height: 20px !important;
            bottom: 5px !important;
          }
        }
        .user_name {
          font-size: 14px;
          line-height: 136%;
        }
      }
      .notify_date {
        font-size: 9px;
      }
    }
    .notify_text {
      font-size: 12px;
      padding-left: 42px;
    }
  }
}
