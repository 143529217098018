@import "../../../../../styles/variables.scss";

.filter__btn__style {
  color: $unnamed__color__17004f;
  font-size: 11px;
  height: 28px;
  border: 1px solid $unnamed__color__dddddd;
  border-radius: 30px;
  padding: 0 1rem;
  white-space: nowrap;
  &.active {
    background-color: var(--Primary-purple);
    color: $unnamed__color__FFFFFF;
  }
}
