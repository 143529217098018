.title {
  color: #000;
  font-weight: 400;
  /* Heading Xl/Reqular */
  font-size: var(--ft__28);
  line-height: 147%; /* 41.16px */
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-bottom: 24px;
  white-space: pre;
}

// ====== start media max width 1399 ===== //
@media (max-width: 1399px) {
  .title {
    // font-size: var(--ft__22);
    font-size: 20px;
    // margin-bottom: 18px;
  }
}
// ====== end media max width 1399 ===== //
