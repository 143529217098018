.swiper_container {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 50px;
    width: calc(100% - 100px);
    height: 3px;
    background-color: #dddddd;
    border-radius: 50px;
    transform: translateY(100%);
  }

  .swiper {
    padding: 0 50px 20px;
    position: relative;
    //   background-color: red;
    button.tab {
      // background-color: #fff;
      text-align: center;
      width: 100%;
      font-size: var(--ft__20);
      text-transform: capitalize;
      color: var(--Secondary-text, #707070);
      transition: 0.3s ease-in-out;
      &::after {
        content: "";
        position: absolute;
        bottom: -17px;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 50px;
        //   transform: translateY(100%);
        transition: 0.3s ease-in-out;
        transform: scale(0);
      }
      &.active__ {
        color: var(--Primary-purple, #6278ff);
        &::after {
          background-color: var(--Primary-purple, #6278ff);
          transform: scale(1);
        }
      }
    }
    .next__button,
    .prev__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background-color: #6278ff;
      position: absolute;
      right: 0;
      top: 37%;
      transform: translate(0%, -50%);
      z-index: 10;
      box-shadow: 0px 8px 10px rgba(98, 120, 255, 0.16);
      transition: 0.3s ease-in-out;
      &.no_navigation_web {
        display: none;
      }
      svg {
        path {
          transition: 0.3s ease-in-out;
        }
      }
      &.swiper-button-disabled {
        background-color: #f7f7f7;
        box-shadow: none;
        svg {
          path {
            stroke: var(--icons-color);
          }
        }
      }
    }
    .prev__button {
      transform: translateY(-50%) rotate(180deg);
      right: auto;
      left: 0;
    }
  }
}
