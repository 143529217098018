.gifts__continer {
  .gift {
    margin: 1rem 0;
    border: 1px solid #00000027;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
    .img__wrapper {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-shadow: 1px 1px 10px #0000001e;
      background-color: #00000005;
      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
