.row {
  .skeleton {
    height: 71px;
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
  }
}

@media (max-width: 1399px) {
  .row {
    .skeleton {
      height: 42px;
    }
  }
}
