.switch_btn_container {
  border-radius: 50px;
  width: 52px;
  height: 32px;
  background: var(--medium-green-bg, #ccfcd6);
  position: relative;
  flex-shrink: 0;
  .switch_icon {
    transition: 0.3s linear;
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #0acf83;
    color: #fff;
  }
  &.unchecked {
    background: var(--gray-background, #f7f7f7);
    .switch_icon {
      transform: translateX(-20px);
      background-color: #dddddd;
    }
  }
}
