.select_box_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  flex: 1;

  .select_box {
    cursor: pointer;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 15px;
    background: var(--gray-background, #f7f7f7);
    color: var(--Primary-purple, #6278ff);
    font-size: var(--ft__20);
    min-width: 160px;
    width: 100%;

    .icon {
      width: 24px;
      height: 24px;
      > svg {
        width: 100%;
        height: 100%;
        path {
          stroke: #6278ff;
        }
      }
    }
    .dropdown_icon {
      &.opened {
        transform: rotateX(180deg);
      }
    }
    &.default {
      background: var(--gray-background, #f7f7f7);
      color: var(--Secondary-text, #707070);
      svg {
        path {
          stroke: var(--Secondary-text, #707070);
        }
      }
    }
  }
  .drop_down_menu {
    min-width: 160px;
    position: absolute;
    top: calc(100% + 5px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: #f7f7f7;
    padding: 14px 0 20px;
    // margin-right: 10px;
    justify-content: space-between;
    z-index: 20;
    border-radius: 15px;
    // row-gap: 19px;
    pointer-events: none;
    max-height: 300px;
    overflow-x: hidden;
    &.opened {
      pointer-events: visible;
      cursor: pointer;
      opacity: 1;
    }
    &.top {
      transform: translateY(-243px);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .option {
      flex: 1 1;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 10px 20px;
      justify-content: space-between;
      // transition: 0.3s ease-in-out;
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__20);
      text-transform: capitalize;
      &.active {
        color: var(--Primary-purple, #6278ff);
      }
      &:hover {
        color: var(--Primary-purple, #6278ff);
      }
    }
  }
}

// ====== start media max width 1399 ===== //
@media (max-width: 1399px) {
  .select_box_container {
    .select_box {
      cursor: pointer;
      gap: 8px;
      padding: 10px 12px;
      border-radius: 10px;
      font-size: var(--ft__16);
      .icon {
        width: 20px;
        height: 20px;
      }
      .dropdown_icon {
        > svg {
          width: 16px;
        }
      }
    }
    .drop_down_menu {
      padding: 14px;
      border-radius: 10px;
      .option {
        font-size: var(--ft__16);
        > svg {
          width: 15px;
        }
      }
    }
  }
}
// ====== end media max width 1399 ===== //
