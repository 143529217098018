.report__details__wrapper {
  .heading {
    font-size: 1.25rem;
  }
  .report_id {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
  .report__img {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  .actions__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1rem;
    .action__item {
      padding: 1rem;
      border-radius: 8px;
      border: 1px solid #00000034;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--Secondary_Blue);
      .date {
        font-size: 12px;
        color: #606060;
      }
    }
  }
}
