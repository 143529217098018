.sidebar_container {
  min-height: calc(100vh - 100px);
  position: fixed;
  top: 100px;
  background: #fff;
  width: 300px;
  // padding: 40px 5px 120px 44px;
  left: 0;
  overflow-x: hidden;
  max-height: 100vh;
  border-right: var(--width_border) solid var(--gray-background, #f7f7f7);
  transition: 0.3s ease-in-out;
  &:lang(ar) {
    left: auto;
    right: 0;
    border-right: none;
    border-left: var(--width_border) solid var(--gray-background, #f7f7f7);
  }

  &.close_menu {
    width: 100px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: var(--gray-background, #f7f7f7);
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6277ff88;
    border-radius: 50px;
  }

  .sidebar_content {
    padding: 40px 5px 120px 44px;
    &:lang(ar) {
      padding: 40px 44px 120px 5px;
    }
    &.close {
      // display: none;
      animation: hidecontent 0.3s ease-in-out forwards;
    }
  }

  .small_menu {
    display: block;
    > ul {
      padding: 40px 5px 120px;
    }
    .close {
      display: none;
    }
  }
}

.menu__btn {
  color: #fff;
  position: absolute;
  right: 0;
  top: 10px;
  background: #6278ff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes hidecontent {
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

// =============== start media max width 1399px ============== //
@media (max-width: 1399px) {
  .sidebar_container {
    min-height: calc(100vh - 58px);
    top: 58px;
    width: 220px;
    .sidebar_content {
      padding: 24px 5px 80px !important;
    }
    &.close_menu {
      width: 80px;
    }

    .small_menu {
      > ul {
        padding: 24px 5px 80px;
      }
    }
  }
}
// =============== end media max width 1399px ============== //
// =============== start media max width 991px ============== //
@media (max-width: 991px) {
  .sidebar_container {
    top: 70px;
    background-color: #fff;
    z-index: 10000;
    left: 0;
    transform: translateX(-100%);
    &.opened {
      transform: translateX(0%);
    }
    &:lang(ar) {
      left: auto;
      right: 0;
      transform: translateX(100%);
      &.opened {
        transform: translateX(0%);
      }
    }
  }
}
// =============== end media max width 991px ============== //
