.card_header {
  img {
    height: 71px;
    width: 71px;
    border-radius: 50%;
  }
  .card_name {
    color: var(--Main-Text, #080e23);
    font-size: 25px;
    text-transform: capitalize;
  }
  .create_at {
    color: var(--Secondary-text, #707070);
    font-size: var(--ft__16);
    text-transform: capitalize;
  }
  .setting_btn {
    height: 72px;
    padding: 0 40px;
    font-size: var(--ft__18);
    text-transform: capitalize;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}
.room_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 45px;
  margin-top: 37px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 18px;
    h5 {
      color: var(--Secondary-text, #707070);
      font-size: var(--ft__20);
      text-transform: capitalize;
    }
    > div {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__16);
      font-weight: 400;
    }
    > span {
      text-transform: capitalize;
    }
    .topic,
    .unactive,
    .active {
      font-size: var(--ft__20);
    }
    .active {
      color: var(--Secondary-Green, #0acf83);
    }
    .unactive {
      color: var(--Secondary-Orange, #f24e1e);
    }
    .topic {
      color: var(--Secondary-Blue, #1363df);
    }
  }
}
