.theme_card {
  .card_image {
    height: 208px;
    // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    display: block;
    margin: 16px 0;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      z-index: 1;
      position: relative;
    }
    &::after {
      content: "";
      border-radius: 12px;
      position: absolute;
      top: 0;
      // left: 31px;
      left: 0;
      width: calc(100%);
      height: 100%;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.103);
      -webkit-backdrop-filter: blur(3.5px);
      box-shadow: 0px 8px 8px 0px #c6c2c3;
    }
  }
  .date {
    color: var(--Secondary-text, #707070);
    font-size: 12px;
    letter-spacing: 0.12px;
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: var(--Secondary-Cyan, #1abcfe);
    font-size: 14px;
    letter-spacing: 0.14px;
    svg {
      width: 18px;
      height: 18px;
      path {
        stroke: var(--Secondary-Cyan, #1abcfe);
      }
    }
  }
  .icon {
    color: #ffc164;
    font-size: 24px;
  }
  .card_title {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__18);
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 17px;
  }
  .main_theme {
    border-radius: 15px;
    background: var(--medium-green-bg, #ccfcd6);
    padding: 4.5px 16px;
    color: var(--Secondary-Green, #0acf83);
    font-size: var(--ft__16);
    text-transform: capitalize;
  }
}
