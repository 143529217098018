.card {
  padding: 20px 10px 25px;
  border-radius: 15px;
  background-color: hsl(229deg 100% 94% / 50%);
  .card_icon {
    width: 102px;
    height: 102px;
    border-radius: 50%;
    background-color: var(--Primary-purple, #6278ff);
  }
  .role {
    margin: 25px 0 15px;
    color: #000;
    font-size: 25px;
    text-transform: capitalize;
  }
  .card_num_premissions {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__18);
    letter-spacing: 0.18px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .link {
    color: var(--Secondary-Blue, #1363df);
    font-size: var(--ft__16);
    text-decoration-line: underline;
    text-transform: capitalize;
  }
  &.secondary_cyan {
    background: rgb(209 254 254 / 50%);
    .card_icon {
      background-color: var(--Secondary-Cyan, #1abcfe);
    }
    .link {
      color: var(--Secondary-Cyan, #1abcfe);
    }
  }
  &.primary_yellow {
    background: rgb(254 245 217 / 50%);
    .card_icon {
      background-color: var(--Primary-Yellow, #fcb042);
    }
    .link {
      color: var(--Primary-Yellow, #fcb042);
    }
  }
  &.secondary_green {
    background: rgb(204 252 214 / 50%);
    .card_icon {
      background-color: var(--Secondary-Green, #0acf83);
    }
    .link {
      color: var(--Secondary-Green, #0acf83);
    }
  }
  &.secondary_blue {
    background: rgb(207 232 253 / 50%);
    .card_icon {
      background-color: var(--Secondary-Blue, #1363df);
    }
    .link {
      color: var(--Secondary-Blue, #1363df);
    }
  }
  &.secondary_purple {
    background: rgb(242 221 255 / 50%);
    .card_icon {
      background-color: var(--Secondary-Purple, #a259ff);
    }
    .link {
      color: var(--Secondary-Purple, #a259ff);
    }
  }
}
