.payment_card {
  padding: 27px;
  border-radius: var(--radius-16);
  background: hsla(229, 100%, 94%, 0.5);
  gap: 20px;
  &.visa {
    background-color: hsla(180, 96%, 91%, 0.5);
    .card_money {
      color: var(--Secondary-Cyan, #1abcfe);
    }
  }
  &.paypal {
    background-color: hsla(277, 100%, 93%, 0.5);
    .card_money {
      color: var(--Secondary-Purple, #a259ff);
    }
  }
  &.discover {
    background-color: hsla(45, 95%, 92%, 0.5);
    .card_money {
      color: var(--Primary-Yellow, #fcb042);
    }
  }
  .card_header {
    gap: 12px;
    .card_title {
      color: var(--Main-Text, #080e23);
      font-size: var(--ft__18);
      line-height: 147%; /* 26.46px */
      text-transform: capitalize;
      font-weight: 400;
    }
  }
  .card_money {
    color: var(--Primary-purple, #6278ff);
    font-size: var(--ft__28);
    line-height: 147%; /* 41.16px */
    text-transform: capitalize;
  }
  .transaction {
    color: var(--Secondary-text, #707070);
    font-size: 14px;
    line-height: 147%; /* 20.58px */
    text-transform: capitalize;
    font-weight: 400;
  }
}
// =========== Start media max-width: 1399px ======== //
@media (max-width: 1399px) {
  .payment_card {
    padding: 18px;
    flex-grow: 1;
    .card_money {
      font-size: 24px;
    }
    .transaction {
      font-size: 14px;
      white-space: pre;
    }
  }
}
// =========== End media max-width: 1399px ======== //
