.input_container {
  border-radius: var(--radius-16);
  flex-shrink: 0;
  // border: 1.5px solid var(--Border-color, #ddd);
  // background: var(--gray-background, #f7f7f7);
  background-color: #fff;
  height: 88px;
  box-sizing: border-box;
  padding: 0 24px;
  gap: 16px;
  &.textarea {
    // min-height: 88px;
    height: auto;
    padding: 24px;
    border: 1.5px solid var(--Border-color, #ddd);
  }
  &.error {
    border-color: #ff00008a;
  }
  .label {
    top: 0;
    left: 21px;
    color: var(--Secondary-text, #707070);
    // background: var(--gray-background, #f7f7f7);
    background-color: #fff;
    font-size: var(--ft__16);
    line-height: 147%;
    letter-spacing: 0.18px;
    text-transform: capitalize;
    padding: 0 4px;
    transform: translate(-0%, -50%);
    font-weight: 400;
    z-index: 1;
    &:lang(ar) {
      left: auto;
      right: 21px;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .input {
    all: unset;
    text-align: left;
    border: 1.5px solid var(--Border-color, #ddd);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-16);
    padding-left: 64px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: var(--ft__22);
    &:lang(ar) {
      text-align: right;
      padding-right: 64px;
      padding-left: 20px;
    }
    &::placeholder {
      font-size: 21px;
      text-transform: capitalize;
      color: var(--Secondary-text, #707070);
      font-weight: 400;
    }
    &.no_icon {
      padding-left: 24px;
    }
  }
  .error_msg {
    top: 0;
    right: 18px;
    transform: translateY(-24px);
    color: var(--Secondary-Orange, #f24e1e);
    font-size: 14px;
    &:lang(ar) {
      left: 18px;
      right: auto;
    }
  }
  .show__pass__btn {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Secondary-text, #707070);
    &:lang(ar) {
      right: auto;
      left: 20px;
    }
  }
  &.error {
    .label,
    .input {
      border-color: var(--Secondary-Orange, #f24e1e);
      &::placeholder {
        color: var(--Secondary-Orange, #f24e1e);
      }
    }
    .icon {
      svg {
        path {
          fill: var(--Secondary-Orange, #f24e1e);
        }
      }
    }
    .label {
      color: var(--Secondary-Orange, #f24e1e);
    }
    .show__pass__btn {
      color: var(--Secondary-Orange, #f24e1e);
    }
  }
  .text_area {
    position: relative;
    height: auto;
    padding: 0 !important;
    border: none;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 0;
  }
}

// ============== start media max width 1399px =========== //
@media (max-width: 1399px) {
  .input_container {
    height: 56px;
    padding: 0 24px;
    gap: 16px;
    .label {
      font-size: 12px;
    }
    .input {
      text-align: left;
      border: 1.5px solid var(--Border-color, #ddd);
      position: absolute;
      border-radius: 12px;
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
      }
    }
    .error_msg {
      font-size: 12px;
      top: 8px;
    }
    .text_area {
      position: relative;
      height: auto;
      padding: 0 !important;
      border: none;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 0;
    }
  }
}
// ============== end media max width 1399px =========== //
