@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// @font-face {
//   font-family: "Gilroy";
//   src: local("Gilroy-Light"), url(./Fonts/Gilroy-Light.ttf) format("truetype");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "Gilroy";
//   src: local("Gilroy-Regular"),
//     url(./Fonts/Gilroy-Regular.ttf) format("truetype");
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Gilroy";
//   src: local("Gilroy-Medium"), url(./Fonts/Gilroy-Medium.ttf) format("truetype");
//   font-weight: 500;
// }

// @font-face {
//   font-family: "Gilroy";
//   src: local("Gilroy-Medium"),
//     url(./Fonts/Gilroy-SemiBold.ttf) format("truetype");
//   font-weight: 600;
// }

// @font-face {
//   font-family: "Gilroy";
//   src: local("Gilroy-Bold"), url(./Fonts/Gilroy-Bold.ttf) format("truetype");
//   font-weight: 700;
// }

* {
  font-weight: 500;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
html,
body {
  max-width: 100vw;
}
ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
a,
button {
  all: unset;
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: var(--gray-background, #f7f7f7);
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6277ff88;
    border-radius: 50px;
  }
}

// ======================= //

// ======================= //

body {
  // font-family: "Gilroy", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  &:lang(ar) {
    font-family: "Almarai", sans-serif !important;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: var(--gray-background, #f7f7f7);
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6277ff88;
    border-radius: 50px;
  }
}

:root {
  // COLORS
  --gray-background: #f7f7f7;
  --Primary-purple: #6278ff;
  --Primary_Dark_Purple: #192354;
  --Secondary-Purple: #a259ff;
  --medium-light-purple-bg: #f2ddff;
  --Primary-Black: #080e23;
  --Primary-Yellow: #fcb042;
  --medium-yellow-bg: #fef5d9;
  --Secondary-Green: #0acf83;
  --Secondary_Blue: #1363df;
  --Secondary-Cyan: #1abcfe;
  --medium-cyan-bg: #d1fefe;
  --Secondary-Orange: #f24e1e;
  --Main-Text: #080e23;
  --Secondary-text: #707070;
  --Border-color: #dddddd;
  --icons-color: #9a9a9a;
  --White_Text: #ffffff;
  --Black_Icon: #080e23;
  --primary_Purple_Text: #6278ff;
  --primary-colors-purple-color-purple-1: #dfe5ff;
  --medium-purple-bg: #dfe5ff;

  // FONT SIZES
  --ft__12: 12px;
  --ft__14: 14px;
  --ft__16: 14px;
  --ft__18: 16px;
  --ft__20: 18px;
  --ft__22: 20px;
  --ft__24: 22px;
  --ft__26: 24px;
  --ft__27: 25px;
  --ft__28: 26px;
  --ft__32: 30px;
  --ft__34: 34px;

  // FONT WEIGHTS
  --ft__w__light: 300;
  --ft__w__regular: 400;
  --ft__w__medium: 500;
  --ft__w__semiBold: 600;
  --ft__w__bold: 700;
  --ft__w__extraBold: 800;
  --ft__w__black: 900;

  // RADIUS
  --radius: 20px;
  --radius-16: 16px;

  --width_border: 8px;
}

// ==== drop_down_menu (side_bar) ====
button[aria-expanded="true"].dropdown_menu {
  // background: var(--primary-colors-purple-color-purple-1, #dfe5ff);
  color: var(--Primary-purple, #6278ff);
  > .arrow_icon {
    svg {
      transform: rotate(180deg);
      path {
        fill: var(--Primary-purple, #6278ff);
      }
    }
  }
  :not(.arrow_icon) {
    svg {
      path {
        stroke: var(--Primary-purple, #6278ff);
      }
    }
  }
}

// ====== layout =======
.main_layout {
  position: relative;
  // min-height: calc(100vh - 100px);
  margin-top: 100px;
  margin-left: 300px;
  transition: 0.3s ease-in-out;
  background-color: #f7f7f7;
  // overflow: hidden;
  &:lang(ar) {
    margin-right: 300px;
    margin-left: 0px;
  }
  &.closed_menu {
    margin-left: 100px;
  }
  main {
    min-height: calc(100vh - 100px);
    border-right: var(--width_border) solid #f7f7f7;
    border-bottom: var(--width_border) solid #f7f7f7;
    background-color: #fff;
    &:lang(ar) {
      border-left: var(--width_border) solid #f7f7f7;
      border-right: 0;
    }
  }
}
// ==== bar chart design ====== //
.bar_chart_container {
  position: relative;
  left: -30px;
  height: 100%;
  max-height: 285px;
  .chart__bar {
    .XAxis,
    .YAxis {
      line {
        stroke: #f7f7f7;
        stroke-width: 6;
      }
      g {
        line {
          display: none;
        }
      }
      tspan {
        fill: #707070;
      }
    }
    g {
      &.recharts-cartesian-grid-horizontal {
        line {
          &:last-of-type,
          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }
}

// ==== main_btn =====
.main_btn {
  border-radius: 20px;
  background: var(--Primary-purple, #6278ff);
  box-shadow: 0px 8px 10px 0px rgba(98, 120, 255, 0.16);
  color: #fff;
  font-size: var(--ft__18);
  text-transform: capitalize;
  box-sizing: border-box;
  &.add_btn {
    padding: 0px 40px;
    height: 72px;
  }
  &.outline {
    background-color: #fff;
    color: var(--Primary-purple, #6278ff);
    border: 2px solid var(--Primary-purple, #6278ff);
    width: 244px;
    padding: 0;
    box-shadow: none;
    text-align: center;
    font-size: 25px;
  }
}

// ==== dash | ====
.dash {
  height: 30px;
  width: 2px;
  background-color: var(--Border-color, #ddd);
}

// =======
.modal-open {
  padding-right: 0 !important;
}

// ==== paginatiln ======
.pagination {
  gap: 10px;
  align-items: center;
  .page-item {
    &:last-of-type,
    &:first-of-type {
      .page-link {
        /* background-color: red; */
        background: var(--Primary-purple) !important;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
      }
      &.disabled {
        background: #dddd !important;
        color: #333;
        opacity: 0.5;
      }
    }
    &.active {
      .page-link {
        color: #fff;
        background-color: var(--Primary-purple) !important;
      }
    }
    .page-link {
      border: none !important;
      background-color: transparent !important;
      // color: #9a9a9a;
      background-color: #fff !important;
      color: var(--Primary-purple);
      font-weight: 500;
      border-radius: 4px;
      font-size: 12px;
    }
  }
}
// ==========
.main_pagination {
  li.disabled {
    background: var(--gray-background, #f7f7f7) !important;
    svg {
      path {
        stroke: #9a9a9a !important;
      }
    }
  }
}

.members_no {
  color: var(--Primary-purple, #6278ff);
  font-size: var(--ft__20);
  text-transform: capitalize;
  font-weight: 400;
}
.page {
  display: flex;
  flex-direction: column;
  row-gap: var(--width_border);
}
.card_title {
  color: #000;
  font-size: var(--ft__28);
  text-transform: capitalize;
  margin-bottom: 18px;
}

// ========== Skelton ============== //
.skeleton {
  cursor: progress;

  background: linear-gradient(90deg, #ddd 40%, #efefefaa, #ddd 60%) right / 300%
    100%;
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  to {
    background-position: left;
  }
}
.skeleton {
  border-radius: 6px;
}
.skeleton_text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.skeleton-table {
  width: 100%;
  padding: 20px;
  display: block;
  background-color: #dddddd75;
}

.skeleton-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 60px;
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
.skeleton-column {
  flex: 1;
  height: 30px;
  display: block;
  position: relative;
  height: 30px;
  width: 100%;
  border-radius: 4px;
  background-color: silver;

  background-color: #e6e6e6;
  background: linear-gradient(90deg, #ddd 40%, #efefefaa, #ddd 60%) right / 300%
    100%;
  animation: skeleton-loading 1.5s linear infinite;
}
.skeleton-column:first-child {
  width: 60px;
}

// ========== Skelton ============== //
// .skeleton {
//   display: inline-block;
//   height: 1em;
//   position: relative;
//   overflow: hidden;
//   // background-color: #dddbdd;
//   background-color: #dddbddaf;
//   border-radius: 4px;
//   display: block;
//   &::after {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     transform: translateX(-100%);
//     background-image: linear-gradient(
//       90deg,
//       rgba(#fff, 0) 0,
//       rgba(#fff, 0.2) 20%,
//       rgba(#fff, 0.5) 60%,
//       rgba(#fff, 0)
//     );
//     animation: shimmer 1s infinite;
//     content: "";
//   }
// }

// @keyframes shimmer {
//   100% {
//     transform: translateX(100%);
//   }
// }

// ========== Start media max width  1399px ======== //
@media (max-width: 1399px) {
  :root {
    --width_border: 4px;
  }
  .main_layout {
    // min-height: calc(100vh - 58px);
    margin-top: 58px;
    margin-left: 220px;
    &:lang(ar) {
      margin-left: auto;
      margin-right: 220px;
    }
    main {
      min-height: calc(100vh - 58px);
    }
  }
  .bar_chart_container {
    position: relative;
    left: -30px;
    height: 100%;
    max-height: 255px;
  }
  .members_no {
    font-size: 12px;
  }
  .main_btn {
    border-radius: 12px;
    font-size: var(--ft__14);
    svg {
      width: 20px;
    }
    &.add_btn {
      padding: 0px 20px;
      height: 52px;
    }
    &.outline {
      background-color: #fff;
      color: var(--Primary-purple, #6278ff);
      border: 2px solid var(--Primary-purple, #6278ff);
      width: 244px;
      padding: 0;
      box-shadow: none;
      text-align: center;
      font-size: 25px;
    }
  }
}
// ========== end media max width  1399px ======== //
// ========== Start media max width  991px ======== //
@media (max-width: 991px) {
  .main_layout {
    margin-left: 0px;
    &:lang(ar) {
      margin-right: 0;
    }
    main {
      padding: 0 var(--width_border) var(--width_border) var(--width_border);
      &:lang(ar) {
        padding: 0 var(--width_border) var(--width_border) var(--width_border);
      }
    }
  }
}
// ========== end media max width  991px ======== //
.svga {
  width: 40px;
}

// === spinner ==== /
.lds-ellipsis {
  display: inline-block;
  position: relative;
  // width: 80px;
  height: 13px;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  // background: #fff;
  background: #9b9a9a;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// ========= posts slider ========= //

.posts_slider {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    background-color: #6278ff;
    font-size: 14px;
    color: #fff;
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -2px;
  }
}
