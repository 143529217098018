.page_header {
  padding-bottom: 18px;
  position: relative;
  margin-bottom: 34px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--width_border);
    background-color: var(--gray-background);
    border-radius: 50px;
    transform: translateY(100%);
  }
  h2 {
    margin-bottom: 0;
  }
}

// ====== start media max-wdith 1399px ====== //
@media (max-width: 1399px) {
  .page_header {
    // padding-bottom: 14px;
    // margin-bottom: 28px;
  }
}
// ====== end media max-wdith 1399px ====== //
