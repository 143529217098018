@import "../../../../styles/variables.scss";

.member__connections__wrapper {
  padding: 1.5rem 1.25rem;
  border-bottom: 4px solid var(--gray-background);
  .section__head {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .member__connections__btns__wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    .filter__btn__item {
      display: flex;
      width: 100%;
      .filter__btn {
        font-size: 14px;
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        font-weight: 400;
        color: #9b9b9b;
        border-bottom: 3px solid #ddd;
        &.active {
          font-weight: 500;
          border-color: var(--Primary-purple);
          color: var(--Primary-purple);
        }
      }
    }
  }
  .connections__wrapper {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.75rem;
  }
}

@media screen and (min-width: "767px") {
  .member__connections__wrapper {
    border-bottom: none !important;
  }
}
