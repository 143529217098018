.label__container {
  gap: 8px;
  color: var(--Main-Text, #080e23);
  font-size: var(--ft__16);
  text-transform: capitalize;
  .user_image {
    position: relative;
    flex-shrink: 0;
    .user_notify_active {
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #fff;
      background-color: #0acf83;
      bottom: -3px;
      z-index: 10;
      &.offline {
        background-color: #f24e1e;
      }
    }
  }
  .label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 120px;
    text-align: left;
    &:lang(ar) {
      text-align: right;
    }
  }
}
@media (max-width: 1399px) {
  .label__container {
    font-size: 12px;
    .user_image {
      .user_notify_active {
        width: 10px;
        height: 10px;
        bottom: 0px;
      }
    }
  }
}
