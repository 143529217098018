.main_link {
  // all: unset;
  width: 100%;
  cursor: pointer;
  gap: 16px;
  color: var(--Main-Text, #080e23);
  font-size: var(--ft__18);
  line-height: 147%; /* 26.46px */
  letter-spacing: 0.18px;
  text-transform: capitalize;
  padding: 14px;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
  &.closed_menu {
    padding: 8px;
    justify-content: center;
    border-radius: 8px;
    .icon {
      width: 20px;
      height: 20px;
      > svg {
        path {
          stroke: var(--Primary-Black, #080e23);
        }
      }
    }
  }
  &.opened,
  &:hover {
    color: var(--Primary-purple, #6278ff);
    .icon {
      > svg {
        path {
          stroke: var(--Primary-purple, #6278ff);
        }
      }
    }
    .arrow_icon {
      > svg {
        path {
          fill: var(--Primary-purple, #6278ff);
        }
      }
    }
  }
  &.active {
    background: var(--primary-colors-purple-color-purple-1, #dfe5ff);
    color: var(--Primary-purple, #6278ff);
    .icon {
      > svg {
        path {
          stroke: var(--Primary-purple, #6278ff);
        }
      }
    }
    .arrow_icon {
      > svg {
        // transform: rotate(180deg);
        path {
          fill: var(--Primary-purple, #6278ff);
        }
      }
    }
  }
  .icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    > svg {
      width: 100%;
      height: 100%;
      path {
        transition: 0.3s ease-in-out;
        stroke: var(--Primary-Black, #080e23);
        // stroke: var(--Main-Text, #080e23);
      }
    }
  }
  .active_icon {
    position: absolute;
    display: flex;
    // align-items: center;
    justify-content: center;
    left: -44px;
    top: 50%;
    transform: translateY(-50%);
    width: 38px;
    height: 38px;
  }
  .arrow_icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    &:lang(ar) {
      right: auto;
      left: 13px;
    }
    &.closed {
      position: absolute;
      left: auto;
      right: 0;
      top: 50%;
      > svg {
        width: 14px;
      }
    }
    > svg {
      transition: 0.3s ease-in-out;
      width: 100%;
      height: 100%;
      path {
        transition: 0.3s ease-in-out;
      }
    }
    &.opened {
      > svg {
        transform: rotateZ(180deg);
      }
    }
  }
}
.dropdow_menu {
  padding-left: 24px;
  overflow: hidden;
  &:lang(ar) {
    padding-left: 0;
    padding-right: 24px;
  }
  &.closed {
    padding: 0;
  }
}
.active_icon {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 44px;
  // top: 252px;
  width: 38px;
  height: 38px;
  transition: 0.3s ease-in-out;
  transform: translateY(20%);
  opacity: 0;
  &.active {
    opacity: 1;
  }
  &:lang(ar) {
    left: auto;
    right: 44px;
    transform: translateY(20%) rotate(180deg);
  }
}

// =========== Start media max-width: 1399px ======== //
@media (max-width: 1399px) {
  .main_link {
    font-size: var(--ft__16);
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
    .icon {
      width: 20px;
      height: 20px;
    }
    .arrow_icon {
      width: 14px;
    }
  }
  .active_icon {
    left: 12px;
    width: 24px;
    height: 24px;
    transform: translateY(0%);
    &:lang(ar) {
      left: auto;
      right: 12px;
      transform: translateY(65%) rotate(180deg);
    }
  }
}
// =========== End media max-width: 1399px ======== //
