@media screen and (min-width: "767px") {
  .member__info__page__wrapper {
    .page__row {
      .page__column {
        &:first-of-type {
          &:lang(en) {
            border-right: 4px solid var(--gray-background);
          }
          &:lang(ar) {
            border-left: 4px solid var(--gray-background);
          }
        }
      }
    }
  }
}
