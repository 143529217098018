@import "../../../../../styles/variables.scss";

.transaction__card {
  background-color: $unnamed__color__f7f7f7;
  border-radius: 16px;
  height: 70px;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  .transaction__details {
    display: flex;
    flex-direction: column;
    .transaction__facts {
      margin-left: 2.6rem;
      display: flex;
      align-items: center;
      column-gap: 16px;
      .transaction__id {
        margin-top: 0.2rem;
        font-size: 10px;
        color: $unnamed__color__9a9a9a;
      }
    }
    .transaction__title__wrapper {
      display: flex;
      align-items: center;
      column-gap: 13px;
      .transaction__title {
        font-size: 13px;
        font-weight: 500;
        color: $unnamed__color__202020;
      }
    }
  }
  .transaction__quantity {
    font-size: 12px;
    font-weight: 500;
    color: $unnamed__color__0acf83;
    &.lose {
      color: $unnamed__color__f24e1e;
    }
  }
}
