.image_container {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  flex-shrink: 0;
  .image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
    position: relative;
  }
  .img_shadow {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    filter: blur(3.5px);
  }
}
@media (max-width: 1399px) {
  .image_container {
    position: relative;
    width: 32px;
    height: 32px;
    .img_shadow {
      width: 10px;
      height: 10px;
    }
  }
}
