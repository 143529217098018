.table_container {
  // padding-bottom: 30px;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: var(--gray-background, #f7f7f7);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: var(--Primary-purple);
    border-radius: 4px;
  }
  .table {
    text-align: center;
    .table_head {
      border-radius: 12px;
      overflow: hidden;
      .table_header_tr {
        .table_th {
          background: var(--gray-background, #f7f7f7);
          border: none !important;
          &:first-of-type {
            border-radius: 12px 0 0 12px;
            &:lang(ar) {
              border-radius: 0px 12px 12px 0;
            }
          }
          &:last-of-type {
            border-radius: 0 12px 12px 0;
            &:lang(ar) {
              border-radius: 12px 0 0 12px;
            }
          }
          .item {
            font-weight: 400;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: var(--Secondary-text, #707070);
            font-size: var(--ft__20);
            text-transform: capitalize;
            padding: 0 10px;
            white-space: pre;
            position: relative;
            width: fit-content;
            margin: 0 auto;
            .sorting_btn {
              position: absolute;
              left: -10px;
              &:lang(ar) {
                left: auto;
                right: -10px;
              }
            }
          }
        }
      }
    }
    .table_body {
      .table_tr {
        border-bottom-color: var(--gray-background, #f7f7f7);
        border-bottom-width: 3px;
        .item {
          font-weight: 500;
          height: 71px;
          padding-top: 0;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--Main-Text, #080e23);
          font-size: var(--ft__16);
          // text-transform: capitalize;
          .user_name_cell {
            gap: 8px;
            position: relative;
            img {
              width: 40px;
              height: 40px;
              overflow: hidden;
              border-radius: 50%;
              object-fit: cover;
            }
            > span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 120px;
              text-align: left;
            }
            .user_image {
              position: relative;
              .user_notify_active {
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid #fff;
                background-color: #0acf83;
                bottom: -3px;
                &.offline {
                  background-color: #f24e1e;
                }
              }
            }
          }
          .amount_cell {
            gap: 8px;
            color: var(--primary-purple-text, #6278ff);
          }
          .status_cell {
            gap: 8px;
            width: 110px;
            color: var(--Secondary-Orange, #f24e1e);
            .icon {
              position: relative;
              flex-shrink: 0;
              display: block;
              width: 24px;
              height: 24px;
              background-color: var(--Secondary-Orange);
              border-radius: 50%;
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #fff;
              }
            }
            &.pending {
              color: var(--Primary-Yellow, #fcb042);
              .icon {
                background-color: var(--Primary-Yellow, #fcb042);
              }
            }
            &.done {
              color: var(--Secondary-Green, #0acf83);
              .icon {
                background-color: var(--Secondary-Green, #0acf83);
              }
            }
          }
          .level_cell {
            color: var(--Primary-purple, #6278ff);
            font-size: var(--ft__16);
            text-transform: capitalize;
          }
          .gold_cell {
            color: var(--Primary-Yellow, #fcb042);
            font-size: var(--ft__16);
            text-transform: capitalize;
          }
          .diamond_cell {
            color: var(--Secondary-Cyan, #1abcfe);
            font-size: var(--ft__16);
            text-transform: capitalize;
          }
          .member_cell {
            color: var(--Primary-purple, #6278ff);
            font-size: var(--ft__16);
            text-transform: capitalize;
          }
          .topic_cell,
          .unactive,
          .active {
            color: var(--Secondary-Green, #0acf83);
            font-size: var(--ft__20);
            text-transform: capitalize;
          }
          .unactive {
            color: var(--Secondary-Orange, #f24e1e);
          }
          .edit_cell {
            color: var(--Primary-Yellow, #fcb042);
            text-shadow: 0px 4px 10px rgba(10, 207, 131, 0.16);
            font-size: var(--ft__22);
            text-transform: capitalize;
            svg {
              path {
                stroke: var(--Primary-Yellow, #fcb042);
              }
            }
          }
          .whatsapp_cell {
            color: var(--Secondary-text, #707070);
            font-size: var(--ft__16);
            text-transform: capitalize;
          }
          .topic_cell {
            color: var(--Secondary-Blue, #1363df);
          }
          .chairs {
            color: var(--Primary-Yellow, #fcb042);
          }
          span {
            flex-shrink: 0;
          }
          .animated {
            color: var(--Secondary-Green, #0acf83);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.pagination_container {
  margin-top: 38px;
}
.PaginationControl {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--gray-background, #f7f7f7);
  margin-top: 4px;
}
.empty_table {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 20px;
  background-color: #dfe5ff;
  border-radius: 4px;
  color: #6278ff;
}
// ========== start media max - width: 1399px ========= //
@media (max-width: 1399px) {
  .table_container {
    .table {
      .table_head {
        .table_header_tr {
          .table_th {
            .item {
              height: 36px;
              font-size: 14px;
              white-space: pre;
            }
          }
        }
      }
      .table_body {
        .table_tr {
          .item {
            white-space: pre;
            height: 42px;
            .unactive,
            .active {
              font-size: 14px;
            }
            .diamond_cell {
              font-size: 12px;
            }
            .diamond_img {
              width: 20px;
            }
          }
        }
      }
    }
  }
  .sorting_icon {
    height: 18px;
    width: 18px;
    flex-shrink: 0;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
// ========== end media max - width: 1399px ========= //

.pending,
.Pending,
.under_revision,
.action_taken {
  padding: 0.35rem 0.75rem;
  border-radius: 1000px;
  background-color: #8a8a8a;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  min-width: 120px;
}

.under_revision {
  background-color: #ffa82f;
}

.action_taken {
  background-color: #008a55;
}
