.form {
  row-gap: 27px;
  .label {
    color: var(--Main-Text, #080e23);
    font-size: var(--ft__20);
    text-transform: capitalize;
  }
  .blocked_btn {
    margin-top: 67px;
    border-radius: 20px;
    border: 2px solid var(--Secondary-Orange, #f24e1e);
    color: var(--Secondary-Orange, #f24e1e);
    height: 80px;
    text-align: center;
    font-size: 25px;
    text-transform: capitalize;
  }
}
