.page__header {
  height: 90px;
  border-bottom: 4px solid var(--gray-background);
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  .wrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    span {
      font-size: 1.1rem;
      font-weight: 400;
    }
    .back__btn {
      width: 32px;
      height: 32px;
      background-color: var(--primary-colors-purple-color-purple-1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn_icon {
        margin-right: 2px;
        font-size: 1.2rem;
        // background-color: red !important;
        color: var(--Secondary-Purple) !important;
        &:lang(ar) {
          margin-left: 4px;

          transform: rotate(180deg);
        }
      }
    }
  }
}
