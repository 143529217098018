.card {
  // height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user__facts {
    display: flex;
    column-gap: 1rem;
    .user__avatar__wrapper {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0px 5px 10px #00000036;
      overflow: hidden;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    .user__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .user__name {
        overflow: hidden;
      }
    }
  }
  .user__level,
  .user__gifts {
    display: flex;
    align-items: center;
    align-items: flex-end;
    column-gap: 4px;
    color: var(--Primary-purple);
    span {
      font-size: 8px;
      margin-bottom: -2px;
    }
  }
  .user__gifts {
    color: var(--Secondary-Cyan);
  }
  .user__actions {
    display: flex;
    align-items: center;
    column-gap: 5px;
    .eye__icon {
      color: var(--Secondary-Green);
    }
    .link__icon {
      color: var(--Secondary-Orange);
    }
  }
}
